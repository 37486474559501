import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import CarMakeService from "src/services/carMakeService";
import { CarMake } from "src/types/model";

import { AppDispatch } from "..";
import { catchErrorMessage } from "./messageReducer";

export type CarMakeReducerState = {
  list: CarMake[];
  current?: CarMake | null;
  loading: boolean;
};

const initialState: CarMakeReducerState = {
  list: [],
  current: null,
  loading: false,
};

export const slice = createSlice({
  name: "carMakeReducer",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setList: (state, action: PayloadAction<CarMake[]>) => {
      state.list = action.payload;
    },
    setCurrent: (state, action: PayloadAction<CarMake | null>) => {
      state.current = action.payload;
    },
  },
});

const { setLoading, setList } = slice.actions;
export const { setCurrent } = slice.actions;

export const getCarMakeList = () => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  try {
    const carMakes = await CarMakeService.getCarMakeList();
    dispatch(setList(carMakes));
  } catch (err) {
    dispatch(catchErrorMessage(err));
  }
  dispatch(setLoading(false));
};

export default slice.reducer;
