import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import config from "src/config";
import CookieService from "src/services/cookieService";
import UserPaletteService from "src/services/userPaletteService";
import UserService from "src/services/userService";
import { UserWithBlockedList } from "src/types/query";

import { AppDispatch } from "..";
import { catchErrorMessage } from "./messageReducer";
import {
  clearCurrent as clearCurrentScheme,
  clearFavoriteList,
  clearList as clearSchemeList,
  clearSharedList,
} from "./schemeReducer";
import { setIntialized } from "./uploadReducer";

export type AuthReducerState = {
  user: UserWithBlockedList | undefined;
  loading: boolean;
  previousPath: string | null;
  palette: string[];
};

const initialState: AuthReducerState = {
  user: undefined,
  loading: false,
  previousPath: null,
  palette: [],
};

export const slice = createSlice({
  name: "authReducer",
  initialState,
  reducers: {
    setUser: (
      state,
      action: PayloadAction<UserWithBlockedList | undefined>
    ) => {
      state.user = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setPreviousPath: (state, action: PayloadAction<string | null>) => {
      state.previousPath = action.payload;
    },
    setPalette: (state, action: PayloadAction<string[]>) => {
      state.palette = action.payload;
    },
  },
});

const { setUser, setLoading, setPalette } = slice.actions;
export const { setPreviousPath } = slice.actions;

export const signInWithCookie = (
  callback?: () => void,
  fallback?: (error?: unknown) => void
) => async (dispatch: AppDispatch) => {
  const siteLogin = CookieService.getSiteLogin();
  if (siteLogin && Object.keys(siteLogin).length === 2) {
    dispatch(setLoading(true));

    try {
      const user = await UserService.getMe();
      if (user) {
        dispatch(setUser(user));
        dispatch(getPalette(user.id));
        callback?.();
      } else {
        fallback?.();
      }
    } catch (error) {
      console.log("error: ", error);
      fallback?.(error);
    }
    dispatch(setLoading(false));
  } else {
    fallback?.();
  }
};

export const signOut = () => async (dispatch: AppDispatch) => {
  CookieService.clearSiteLogin();
  dispatch(clearSchemeList());
  dispatch(clearCurrentScheme());
  dispatch(clearSharedList());
  dispatch(clearFavoriteList());
  dispatch(setUser(undefined));
  dispatch(setIntialized(false));
  window.location.href = config.parentAppURL + "/logout";
};

export const getPalette = (userID: number) => async (dispatch: AppDispatch) => {
  try {
    const paletteRes = await UserPaletteService.getPaletteByUserID(userID);
    if (paletteRes.palette) {
      dispatch(setPalette(JSON.parse(paletteRes.palette)));
    }
  } catch (err: unknown) {
    dispatch(catchErrorMessage(err));
  }
};

export const updatePalette = (userID: number, palette: string[]) => async (
  dispatch: AppDispatch
) => {
  try {
    const paletteRes = await UserPaletteService.updatePalette(userID, {
      palette: JSON.stringify(palette),
    });
    dispatch(setPalette(JSON.parse(paletteRes.palette)));
  } catch (err: unknown) {
    dispatch(catchErrorMessage(err));
  }
};

export default slice.reducer;
