import { CarMakeWithBases } from "src/types/query";

import BaseAPIService from "./baseAPIService";

export default class CarMakeService extends BaseAPIService {
  static getCarMakeList = async (): Promise<CarMakeWithBases[]> =>
    this.requestWithAuth(`/carMake`, "GET");

  static getCarMakeByID = async (id: number): Promise<CarMakeWithBases> =>
    this.requestWithAuth(`/carMake/${id}`, "GET");
}
