import config from "src/config";
import { GetCarRaceResponse } from "src/types/query";

import BaseAPIService from "./baseAPIService";

export default class CarService extends BaseAPIService {
  static getCarRace = async (
    builderID: number,
    number: number
  ): Promise<GetCarRaceResponse> =>
    this.directRequestWithAuth(
      `${config.parentAppURL}/builder.php?cmd=loadrace&builder_id=${builderID}&number=${number}`,
      "GET"
    );

  static setCarRace = async (payload: FormData) =>
    this.directRequestWithAuth(
      `${config.parentAppURL}/builder.php?cmd=set`,
      "POST",
      payload,
      0,
      "multipart/form-data"
    );
}
