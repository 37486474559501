import { CarPin } from "src/types/model";

import BaseAPIService from "./baseAPIService";

export default class CarPinService extends BaseAPIService {
  static getCarPinListByUserID = async (userID: number): Promise<CarPin[]> =>
    this.requestWithAuth(`/carPin/byUser/${userID}`, "GET");

  static createCarPin = async (
    userId: number,
    carMakeID: number
  ): Promise<CarPin> =>
    this.requestWithAuth(`/carPin/byUser/${userId}/${carMakeID}`, "POST");

  static deleteCarPin = async (userId: number, carMakeID: number) =>
    this.requestWithAuth(`/carPin/byUser/${userId}/${carMakeID}`, "DELETE");
}
