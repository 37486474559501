import React from "react";

export enum AffixPosition {
  PREFIX = "prefix",
  SUFFIX = "suffix",
}

type AffixContainerProps = {
  affixPosition?: AffixPosition;
  prefix?: JSX.Element;
  suffix?: JSX.Element;
  affix?: JSX.Element;
  children: JSX.Element | JSX.Element[];
};

export const AffixContainer = React.memo(
  ({ affixPosition, prefix, suffix, affix, children }: AffixContainerProps) => (
    <>
      {affixPosition === AffixPosition.PREFIX ? affix ?? prefix : prefix}
      {children}
      {affixPosition === AffixPosition.SUFFIX ? affix ?? suffix : suffix}
    </>
  )
);
