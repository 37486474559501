import { UserMin } from "src/types/model";
import { UserWithBlockedList } from "src/types/query";

import BaseAPIService from "./baseAPIService";

export default class UserService extends BaseAPIService {
  static getMe = async (): Promise<UserWithBlockedList> =>
    this.requestWithAuth(`/user/me`, "GET");

  static getUserByID = async (id: number | string): Promise<UserMin> =>
    this.requestWithAuth(`/user/${id}`, "GET");
}
